.container {
    display: grid;
    grid-template-columns: repeat(10, auto);
    grid-auto-rows: minmax(24px, auto);
    grid-gap: 8px 16px;
    align-items: baseline;
}

.rowContainer {
    display: contents;
    border: 1px solid black;
}

.select {
    grid-column: 1;
}

.mark {
    grid-column: 2;
}

.fileLinks {
    grid-column: 3;
}

.material {
    grid-column: 4;
}

.profile {
    grid-column: 5;
}

.status {
    grid-column: 6;
}

.qty {
    grid-column: 7;
}

.length {
    grid-column: 8;
}

.weight {
    grid-column: 9;
}

.action {
    grid-column: 10;    
}

.statusName {
    grid-column: 2 / 7;
    justify-self: right;
}

.day {
    min-height: 64px;
}

.user {
    padding: 8px;
}

.scheduleGrid {
    width: 100%;
    min-height: calc(100vh - 64px);
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.dayHeader {
    padding: 8px;
}

.dayHeaderDay {
    font-size: 0.8em;
    font-weight: 500;
}

.dayHeaderDate {
    font-size: 1.6em;
    line-height: 1;
    color: #000;
}